import { useCallback, useState } from 'react';
import { Button, Popover, Stack, Text } from '@mantine/core';
import { useParams } from 'react-router-dom';

import classes from '../DropdownMenus.module.css';
import { User } from '../../../icons';
import UserAssignmentItem from './UserAssignmentItem';
import type { EnsisUser, WithoutPermissions } from '../../../types/apiTypes';
import useEnsisMutation from '../../../hooks/useEnsisMutation';
import { hasPermissionsForObject } from '../../../utils/apiUtils';
import useEnsisQuery from '../../../hooks/useEnsisQuery';
import CenteredLoader from '../../CenteredLoader';

interface Props {
  sectionUid: string
  condensed?: boolean
}

const UserAssignmentDropdown: React.FC<Props> = (props: Props) => {
  const { sectionUid, condensed = false } = props;
  const { proposalUid, organization } = useParams();
  const [opened, setOpened] = useState(false);
  const { data: proposalData, isLoading: proposalDataLoading } = useEnsisQuery(
    `/app/proposals/${proposalUid}/data`
  );
  const { data: usersData, isLoading: usersLoading } = useEnsisQuery(
    '/app/users',
    {
      queryParams: {
        organization_slug: organization ?? ''
      }
    }
  );

  const isLoading = proposalDataLoading || usersLoading;
  const section = proposalData?.sections?.find((s) => s.uid === sectionUid);
  const assignedUsers: Array<WithoutPermissions<EnsisUser>> = section?.assigned_users ?? [];
  const canAssignSections = hasPermissionsForObject(proposalData, 'assign_sections');

  const updateSectionAssignmentsMutation = useEnsisMutation(`/app/proposal-sections/${section?.uid}/assignments`, {
    requestType: 'patch',
    showSuccessMessage: false,
    queryKeysToInvalidate: [`/app/proposals/${proposalUid}/data`]
  });

  const sortByName = (items: EnsisUser[]) =>
    items.sort((a, b) => {
      if ((a.first_name ?? '') < (b.first_name ?? '')) {
        return -1;
      }
      if ((a.first_name ?? '') > (b.first_name ?? '')) {
        return 1;
      }
      if ((a.last_name ?? '') < (b.last_name ?? '')) {
        return -1;
      }
      if ((a.last_name ?? '') > (b.last_name ?? '')) {
        return 1;
      }
      return 0;
    });

  const handleOnChange = useCallback(() => {
    if (canAssignSections) {
      setOpened(!opened);
    }
  }, [opened, canAssignSections]);

  const getAssignmentsLabel = useCallback(() => {
    const hasNoAssignments = assignedUsers.length === 0;
    const hasOneAssignment = assignedUsers.length === 1;

    if (hasNoAssignments) {
      return 'Unassigned';
    }

    if (hasOneAssignment) {
      return `${assignedUsers[0].first_name} ${assignedUsers[0].last_name}`;
    }

    return `${assignedUsers.length} Assigned`;
  }, [assignedUsers]);

  const handleAssignmentSelect = useCallback((selectedAssignedUser: WithoutPermissions<EnsisUser>) => {
    const assignmentIndex = assignedUsers.findIndex(
      (assignedUser) => selectedAssignedUser.uid === assignedUser.uid
    );
    if (assignmentIndex === -1) {
      updateSectionAssignmentsMutation.mutate({
        assigned_user_uids: [...assignedUsers.map((a) => a.uid).concat(selectedAssignedUser.uid)]
      });
    } else {
      updateSectionAssignmentsMutation.mutate({
        assigned_user_uids: [...assignedUsers.map((a) => a.uid).filter(
          (uid) => uid !== selectedAssignedUser.uid
        )]
      });
    }
  }, [assignedUsers, updateSectionAssignmentsMutation]);

  if (isLoading) {
    return <CenteredLoader />;
  }

  const label = condensed
    ? <Text maw={200} ml={8} truncate>{getAssignmentsLabel()}</Text>
    : <Text ml={8} truncate>{getAssignmentsLabel()}</Text>;

  return (
    <Popover
      classNames={{ dropdown: classes.popoverDropdown }}
      opened={opened}
      onChange={handleOnChange}
      position={condensed ? 'bottom-end' : 'bottom-start'}
    >
      <Popover.Target>
        <Button
          classNames={{ root: classes.buttonTargetRoot }}
          variant='subtle'
          onClick={(event) => {
            handleOnChange();
            event.stopPropagation();
          }}
          disabled={!canAssignSections}
        >
          <User />
          {label}
        </Button>
      </Popover.Target>
      <Popover.Dropdown>
        <Stack ta='left' gap={2}>
          {sortByName(usersData?.items ?? []).map((user) => (
            <UserAssignmentItem
              handleSelect={handleAssignmentSelect}
              key={user.uid}
              user={user}
              selected={assignedUsers.some(
                (assignment) => assignment.uid === user.uid
              )}
            />
          ))}
        </Stack>
      </Popover.Dropdown>
    </Popover>
  );
};

export default UserAssignmentDropdown;
