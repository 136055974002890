import { Draggable, type DraggableProvided, Droppable, type DraggableRubric } from '@hello-pangea/dnd';
import { ActionIcon, Text, Box, Group, Divider, Checkbox } from '@mantine/core';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useHover } from '@mantine/hooks';

import { useMemo } from 'react';

import { ArrowRight } from '../../icons';
import SectionDropdown from '../../components/DropdownMenus/SectionDropdown';
import { selectOutline } from '../../redux/store';
import { type SectionData } from './SectionEditor';
import { type RequirementType } from './EditOutlineDnd';
import { RequirementDropdown, UserAssignmentDropdown } from '../../components/DropdownMenus';
import classes from './Editor.module.css';
import { getRequirementIdentifierSpan } from '../../utils/requirementUtils';
import {
  getDraggableSubsectionIndex,
  getItemInfoFromDraggableId,
  undoDraggableSubsectionIndex
} from '../../utils/dndUtils';

interface DroppableSectionProps {
  isArchive?: boolean
  sectionDragProvided?: DraggableProvided
  section: SectionData
  onRowClick?: (requirementUid: string) => void
  focusedRequirementUid?: string
  onOpenRename: (sectionUid: string) => void
  onOpenDelete: (sectionUid: string) => void
  onOpenEditRequirement: (req: RequirementType, sectionUid: string) => void
  onOpenAddRequirement?: (sectionUid: string) => void
  onOpenAddSubsection: (sectionUid: string) => void
  sectionWidth: string
  archiveIsDroppable: boolean
  sectionsOpened: Set<string>
  handleSetSectionOpen: (sectionUid: string, isOpen?: boolean) => void
  isReviewSectionsView: boolean
  draggingRequirementResponseUid?: string
  checkedRequirements?: RequirementType[]
  onCheckRequirement?: (reqUid: RequirementType) => void
  showAssignedUsers?: boolean
}
const DroppableSection: React.FC<DroppableSectionProps> = (props: DroppableSectionProps) => {
  const {
    onRowClick,
    focusedRequirementUid,
    sectionDragProvided,
    section,
    isArchive = false,
    onOpenEditRequirement,
    handleSetSectionOpen,
    sectionWidth,
    sectionsOpened,
    archiveIsDroppable,
    isReviewSectionsView,
    draggingRequirementResponseUid,
    checkedRequirements,
    onCheckRequirement,
    showAssignedUsers
  } = props;
  const inBulkEditMode = (checkedRequirements?.length ?? 0) > 0;
  const outlineState = useSelector(selectOutline);
  const isOpen = sectionsOpened.has(section.uid);
  const canOpenSection = !isReviewSectionsView || section.parentSectionUid === undefined;
  const { sectionUid: currentSectionUid } = useParams();

  const draggableReq = (sectionUid: string, req: RequirementType) => {
    const { hovered, ref: hoverRef } = useHover();
    const isDragging = draggingRequirementResponseUid === req.requirementResponseUid;
    const backgroundColor = useMemo(() => {
      if (req.requirementUid === focusedRequirementUid) {
        return 'var(--mantine-color-green-1)';
      }
      if (checkedRequirements?.includes(req) ?? false) {
        return 'var(--mantine-color-lightPurple-1)';
      }
      if (hovered) {
        return 'var(--mantine-color-gray-1)';
      }
      return undefined;
    }, [checkedRequirements, hovered, focusedRequirementUid]);

    return (
      <Group
        bg={backgroundColor}
        style={{
          cursor: inBulkEditMode ? 'pointer' : 'grab'
        }}
        className={classes.draggableRequirement}
        justify='space-between'
        onClick={() => {
          if (onRowClick !== undefined) {
            onRowClick(req.requirementUid);
          } else {
            if (onCheckRequirement !== undefined) {
              onCheckRequirement(req);
            }
          }
        }}
        ref={hoverRef}
      >
        <Group>
        <Box w={20} p={4}>
          {(hovered || inBulkEditMode) && <Checkbox
              radius={4}
              value={req.requirementResponseUid}
              checked={checkedRequirements?.includes(req)}
              onChange={(event) => {
                event.stopPropagation();
                if (onCheckRequirement !== undefined) {
                  onCheckRequirement(req);
                }
              }}
            />
            }
          </Box>
          <Text
            ta='start'
            lineClamp={isDragging ? 1 : undefined} // line clamp to 1 for better dnd ux and behavior while dragging
            maw={`calc(${sectionWidth} - 40px)`}
          >
            {getRequirementIdentifierSpan(req.requirementIdentifier)}
            {req.requirementText}
          </Text>
        </Group>
        {!isArchive && !inBulkEditMode && <RequirementDropdown
          requirementResponseUid={req.requirementResponseUid}
          requirementResponseOrdinal={req.ordinal}
          sectionData={outlineState.sections}
          isOutline={true}
          handleOpenEditRequirement={() => { onOpenEditRequirement(req, sectionUid); }}
          sectionUid={sectionUid}
        />}
      </Group>
    );
  };

  const numberOfReqsInSection = outlineState.requirements[section.uid]?.length ?? 0;
  const numberOfSubsectionsInSection = outlineState.subsections[section.uid]?.length ?? 0;
  const emptySectionText = isArchive
    ? 'No requirements are archived.'
    : (isReviewSectionsView
        ? 'No subsections in this section. Create a new one or move one here from another section.'
        : 'No requirements in this section. Create a new one or move one here from another section.'
      );

  const sectionRequirements = (
    <>
      {numberOfReqsInSection > 0
        ? (outlineState.requirements[section.uid]?.map((req, index) =>
        <div key={req.requirementResponseUid}>
          <Draggable
            key={req.requirementResponseUid}
            index={index}
            draggableId={`req_${req.requirementResponseUid}`}
            isDragDisabled={inBulkEditMode}
          >
            {(provided, snapshot) =>
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                {draggableReq(section.uid, req)}
              </div>
            }
          </Draggable>
          <Divider ml={20} color='var(--mantine-color-greyPurple-2)' />
        </div>))
        : <Text mih={12} />
      }
    </>);

  const getSectionContent = () => {
    if (!isOpen) {
      return <></>;
    }
    if (numberOfReqsInSection === 0 && numberOfSubsectionsInSection === 0) {
      return <Text pl={50} pb={9} pt={10} mih={32}> {emptySectionText} </Text>;
    } else {
      return <>
        {sectionRequirements}
        <>
        {outlineState.subsections[section.uid]?.map((subSection, index) => {
          // within <Droppable> indices must be sequential. adjusting req index to account for subsections
          const adjustedIndex = getDraggableSubsectionIndex(section.uid, index);
          return (

        <div style={{ paddingLeft: 20 }} key={subSection.uid} >
        <Draggable isDragDisabled={inBulkEditMode} draggableId={`subsection_${subSection.uid}`} index={adjustedIndex}>
        {(dragProvided, dragSnapshot) =>
        <div
          {...dragProvided.draggableProps}
          ref={dragProvided.innerRef}
        >
          <DroppableSection
            {...props}
            section={subSection}
            sectionDragProvided={dragProvided}
            sectionWidth={`calc(${sectionWidth} - 40px)`}
          />
          </div>
        }
          </Draggable>
       </div>);
        })
        }
      </>
      </>;
    }
  };

  const sectionTitleGroup = (
    <Group
      onClick={() => {
        if (canOpenSection) {
          handleSetSectionOpen(section.uid);
        }
      }}
      {...sectionDragProvided?.dragHandleProps}
      justify="space-between"
      pl={8}
      pb={9}
      pt={12}
    >
      <Group gap='xs' >
        {canOpenSection && (
         <ActionIcon
            className={isOpen ? classes.chevron : ''}
            variant='subtle'
            size='xs'
            c={isArchive ? 'var(--mantine-color-gray-6)' : 'var(--mantine-color-darkPurple-9)'}
            onClick={() => { handleSetSectionOpen(section.uid); }}
          >
            <ArrowRight/>
          </ActionIcon>)
        }
        <Text
          fw={500}
          c={isArchive ? 'var(--mantine-color-gray-6)' : 'var(--mantine-color-darkPurple-9)'}
          ta='start'
          w={sectionWidth} >
          {isArchive ? `${section.title} (${numberOfReqsInSection})` : `${section.title}`}
        </Text>
      </Group>
      <Group justify="flex-end">
        {(showAssignedUsers ?? false) &&
            <UserAssignmentDropdown
              sectionUid={section.uid}
              condensed={true}
            />
        }
        {!isArchive && !inBulkEditMode &&
          <SectionDropdown
            sections={outlineState.sections}
            sectionUid={section.uid}
            isCurrentPage={currentSectionUid === section.uid}
            isSubsection={section.parentSectionUid !== undefined}
            {...props}
          />
        }
      </Group>
    </Group>
  );

  const getDraggableClone = (provided: DraggableProvided, rubric: DraggableRubric) => {
    const draggableType = getItemInfoFromDraggableId(rubric.draggableId).type;
    return (
      <div
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        ref={provided.innerRef}
        style={{
          height: 32,
          width: 200,
          ...provided.draggableProps.style
        }}
      >
        { draggableType === 'req'
          ? draggableReq(section.uid, outlineState.requirements[section.uid][rubric.source.index])
          : (
          <Text
            fw={500}
            c={isArchive ? 'var(--mantine-color-gray-6)' : 'var(--mantine-color-darkPurple-9)'}
            ta='start'
            w={sectionWidth}
          >
            { /* eslint-disable-next-line max-len */
              outlineState.subsections[section.uid][undoDraggableSubsectionIndex(section.uid, rubric.source.index)]?.title
            }
          </Text>
            )}
      </div>
    );
  };

  return (
    <Droppable
      isDropDisabled={isArchive && !archiveIsDroppable}
      droppableId={`section_${section.uid}`}
      direction="vertical"
      renderClone={ (provided, snapshot, rubric) => getDraggableClone(provided, rubric)}
    >
      {(sectionDropProvided, snapshot) =>
       <Box {...sectionDropProvided.droppableProps}
          style={{
            backgroundColor: (snapshot.isDraggingOver && !isOpen) ? 'var(--mantine-color-greyPurple-2)' : '',
            borderRadius: '4px',
            width: '100%',
            paddingRight: '12px'
          }}
          ref={sectionDropProvided.innerRef}>
          {sectionTitleGroup}
          <Divider color='var(--mantine-color-greyPurple-2)'/>
          {getSectionContent()}
          {!isOpen ? <Box h={0}> {sectionDropProvided.placeholder} </ Box> : sectionDropProvided.placeholder}
        </Box>
      }
    </Droppable>
  );
};
export default DroppableSection;
